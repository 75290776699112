import React from 'react';
// components
import WomensAccordion from '../womens-accordion';
// styles
import styles from './nutritional-information-accordion.module.scss';

interface IProps {
  title: string;
  product: {
    identifier: string;
    pills: Array<any>;
  };
}

const NutritionalInformationAccordion: React.FC<IProps> = ({ product, title }) => {
  const nutritionalInfoItems = product.pills.map((item: any) => ({
    question: (
      <div>
        {item?.pill_image?.filename_disk && (
          <img
            className={styles.nutritionalPillImage}
            src={`${process.env.GATSBY_MEDIA_URL}${item.pill_image.filename_disk}?w=90`}
            alt=""
          />
        )}
        <h4 className={styles.nutritionalPillTitle}>{item.name}</h4>
      </div>
    ),
    answer: (
      <div className={styles.nutritionWrapper}>
        <p>{item.description}</p>
        <table className={styles.table}>
          <tbody>
            <tr>
              <td>&nbsp;</td>
              <td className={styles.alignRight}>Per {item.pill_form}</td>
              <td className={styles.alignRight}>% NRV</td>
            </tr>
            {item.nutrients.map((nutrient: any, index: number) => (
              <tr key={index}>
                <td className={styles.nutrientName}>{nutrient.name}</td>
                <td className={styles.alignRight}>
                  {`${nutrient.amount > 1000000 ? (nutrient.amount / 1000000000).toFixed(1) : nutrient.amount} ${
                    nutrient.unit?.short_name || ' billion'
                  }`}
                </td>
                <td className={styles.alignRight}>{nutrient.nrv ? nutrient.nrv : ' † '}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <p>† NRV not established</p>
        <div dangerouslySetInnerHTML={{ __html: item.ingredients }} />
      </div>
    ),
  }));

  return (
    <section className={styles.sectionWhite}>
      <div className={styles.container}>
        <div className={styles.row}>
          <div className={styles.contentNutritionalInfo}>
            <h2>{title}</h2>

            <WomensAccordion
              isTransparent={true}
              openIfSingleItem={true}
              isLargeRow={true}
              items={nutritionalInfoItems}
            />
          </div>
        </div>
      </div>
    </section>
  );
};
export default NutritionalInformationAccordion;
