import Image from '@components/image/image';
import React from 'react';
// componenets
import Accordion from '../../components/accordion';
// styles
import styles from './what-is-inside.module.scss';

interface IProps {
  title: string;
  description: string;
  product: {
    identifier: string;
    pills: Array<any>;
  };
}

const WhatIsInside: React.FC<IProps> = ({ title, description, product }) => {
  const accordionPillItems = product.pills.map((item: any) => ({
    effect: (
      <div className={styles.desktopPillIconTitle}>
        <Image src={`${process.env.GATSBY_MEDIA_URL}${item.effect_icon.filename_disk}`} width={26} />
        <p>
          <strong>{item.effect_title}</strong>
        </p>
      </div>
    ),
    question: (
      <div>
        {item?.pill_image?.filename_disk && (
          <div className={styles.pillImage}>
            <Image filename={item.pill_image.filename_disk} width={140} alt={item.name} />
          </div>
        )}
        <div className={styles.mobileQuestionTitle}>
          <div className={styles.mobilePillIconTitle}>
            <Image src={`${process.env.GATSBY_MEDIA_URL}${item.effect_icon.filename_disk}`} width={26} />
            <p>
              <strong>{item.effect_title}</strong>
            </p>
          </div>
          <span className={styles.pillTitle}>{item.name}</span>
        </div>
      </div>
    ),
    answer: (
      <div>
        <hr className={styles.hrDark} />
        <p className={styles.tagline}>
          <strong>{item.tagline}</strong>
        </p>
        <p className={styles.pillDescription}>{item.description}</p>
      </div>
    ),
  }));

  return (
    <section className={styles.sectionPills}>
      <div className={styles.containerPills}>
        <div className={styles.row}>
          <div className={styles.contentTitle}>
            <h2>{title}</h2>
            <p>{description}</p>
          </div>
        </div>
        <div className={styles.rowMobile}>
          <div className={styles.contentCentered}>
            <Accordion withEffects={true} items={accordionPillItems} isTransparent={true} />
          </div>
        </div>
      </div>

      <div className={styles.desktopPills}>
        {accordionPillItems.map((pill: any, index: number) => (
          <div className={styles.desktopPill} key={index}>
            {pill.effect}
            <p>{pill.question}</p>
            <p className={styles.desktopPillDescription}>{pill.answer}</p>
          </div>
        ))}
      </div>
    </section>
  );
};

export default WhatIsInside;
