import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
// components
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import HeroHealthProductWithCarousel from '../../components/hero-health-product-with-carousel';
import WhatIsInside from '../../components/what-is-inside';
import VitaminCardsCarousel from '../../components/vitamin-cards-carousel';
import WhyWeAreDifferent from '../../components/why-we-are-different';
import Reviews from '@components/reviews/reviews';
import NutritionalInformationAccordion from '../../components/nutritional-information-accordion';
// hooks
import useGetProductPlansData from '@src/features/shop/hooks/useGetProductPlansData';
import PartnersSlider from '@src/components/partners-slider/partners-slider';

const MindAndBody = () => {
  const content = useStaticQuery(graphql`
    query MindAndBody {
      directusMindAndBody {
        id
        page_title
        page_description
        hero_title
        hero_title_icon {
          filename_disk
        }
        hero_description
        hero_description_2
        hero_icon_list {
          filename_disk
          text
        }
        hero_background_color
        hero_image1 {
          filename_disk
        }
        hero_image2 {
          filename_disk
        }
        logos_title
        whats_inside_description
        whats_inside_title
        whats_inside_title
        whats_inside_items {
          title
          description
          filename_disk
          pill_description
        }
        whats_inside_description
        benefits_title
        benefits_cards {
          filename_disk
        }
        benefits_icon_list {
          filename_disk
          text
        }
        additional_benefits_vitamins {
          filename_disk
          name
          measurement
          benefit_effects {
            effect_icon
            effect_title
          }
          points {
            point
          }
        }
        section_last_title
        section_last_description
        section_last_bullets {
          point
        }
        section_last_image {
          filename_disk
        }
        section_last_tick_image {
          filename_disk
        }
        reviews_title
        reviews_title
        reviews_items {
          newItem
          review
          reviewerName
        }
        nutritional_information_title
        product {
          identifier
          pills {
            pill_image {
              filename_disk
            }
            name
            tagline
            description
            ingredients
            pill_form
            nutrients {
              name
              description
              image {
                filename_disk
                title
              }
              amount
              unit {
                short_name
              }
              nrv
            }
            effect_title
            effect_icon {
              filename_disk
            }
          }
        }
      }
    }
  `);

  const { directusMindAndBody } = content;

  const {
    page_title,
    page_description,
    hero_title,
    hero_title_icon,
    hero_description,
    hero_description_2,
    hero_icon_list,
    hero_image1,
    hero_image2,
    hero_background_color,
    whats_inside_title,
    whats_inside_items,
    whats_inside_description,
    benefits_title,
    benefits_cards,
    benefits_icon_list,
    additional_benefits_vitamins,
    section_last_title,
    section_last_description,
    section_last_bullets,
    section_last_image,
    section_last_tick_image,
    reviews_title,
    reviews_items,
    nutritional_information_title,
    product,
  } = directusMindAndBody;

  const { dataPlans: data, loading: loading, isAvailable } = useGetProductPlansData(
    'mind-and-body'
  );

  return (
    <Layout>
      <SEO title={page_title} description={page_description} />

      <HeroHealthProductWithCarousel
        showFreeDeliveryText={true}
        title={hero_title}
        subtitle={''}
        heroTitleIcon={hero_title_icon}
        descriptions={[hero_description, hero_description_2]}
        heroIconList={hero_icon_list}
        images={[hero_image1, hero_image2]}
        backgroundColor={hero_background_color}
        loadingPlans={loading}
        plans={data ? data.product_plans : []}
        lazyload={false}
        isAvailable={isAvailable}
      />

      <PartnersSlider isWhite />

      <WhatIsInside
        product={product}
        title={whats_inside_title}
        description={whats_inside_description}
      />

      <VitaminCardsCarousel
        background="DarkerCream"
        title={benefits_title}
        benefits={benefits_icon_list}
        vitamins={additional_benefits_vitamins}
      />

      <WhyWeAreDifferent
        image={section_last_image}
        title={section_last_title}
        description={section_last_description}
        items={section_last_bullets}
        tick_image={section_last_tick_image}
      />

      <Reviews title={reviews_title} items={reviews_items} showReview hideTrustPilot />

      <NutritionalInformationAccordion title={nutritional_information_title} product={product} />
    </Layout>
  );
};

export default MindAndBody;
